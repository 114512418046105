import React,{useState,useEffect} from "react"
import axios from "axios"
import parse from "html-react-parser"
import { DetailLoader } from "../Loaders/DetailPageLoader"

const Termscondation = () => {
    const [terms,setTerms]=useState([])
    const [loading,setLoding]=useState(true)

    useEffect(() => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}get-term-condition`,
        })
          .then((res) => {
            let response = res?.data?.data?.termsAndCondition;
            console.log("print terms and condation data",res?.data?.data?.termsAndCondition)
            setTerms(response)
            setLoding(false)

           })
          .catch((err) => {
            console.log("this is error", err);
            setLoding(false)
          });
      }, []);
   
    if(loading){
      return(
        <div className="container my-5">  
        <DetailLoader/>
        </div>
      )
    }
    return (
        <>
            <div className="pagesection">
                <div className="container">
                    <div className="sub-section m-0">
                        <h5 className="title mb-4">Terms and Conditions</h5>
                        <p>{parse(terms?. description||"")}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Termscondation