import React from "react";
import { Link } from "react-router-dom";

function PaymentError() {
  return (
    <div id="payment-error">
      <div class="container">
        <div class="failure-message">
          <div class="failure-icon">
            <i class="bi bi-x-circle-fill"></i>
          </div>
          <div class="failure-title">Order Failed</div>
          <div class="failure-text">
            We're sorry, but there was an issue processing your order.{" "}
            <i> Please place your order again to continue.</i>
          </div>
          <div class="back-button">
            <Link to="/" class="btn btn-danger">
              <i class="bi bi-house-fill"></i> Back to Homepage
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentError;
