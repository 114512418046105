import React from "react";
import parse from "html-react-parser";

const Roomavailable = ({ data, imageurl }) => {
  return (
    <>
      {data.room_images.map((img, keys) => (
        <div
          className={`${
            data.room_images.length > 6 ? "col-lg-2" : "col"
          } restro-img mb-5`}
        >
          <img src={imageurl + "/" + img?.image} alt="room service"></img>
        </div>
      ))}

      {/* <div className="col-lg-6">
          <h6>{data.title}</h6>
          {parse(data.description)}
        </div> */}
    </>
  );
};

export default Roomavailable;
