import React from "react";
import { codes } from "../data/countryCode";

function CheckoutForm({
  checkoutData,
  handleCheckoutData,
  handleMeeting,
  setCheckData,
  heading,
}) {
  return (
    <>
      <div className="form-section">
        <div className="sub-section m-0">
          <h5 className="title m-0">{heading}</h5>
        </div>
        <form className="row">
          <div className="col-lg-6 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              First Name <span>*</span>
            </label>
            <input
              type="text"
              class="form-control input-fld-sec"
              id="exampleFormControlInput1"
              name="first_name"
              value={checkoutData.first_name}
              onChange={handleCheckoutData}
            />
          </div>
          <div className="col-lg-6 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              Last Name <span>*</span>
            </label>
            <input
              type="text"
              class="form-control input-fld-sec"
              id="exampleFormControlInput1"
              name="last_name"
              value={checkoutData.last_name}
              onChange={handleCheckoutData}
            />
          </div>
          <div className="col-lg-12 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              Phone <span>*</span>
            </label>
            <div className="row">
              <div className="col-5">
                <select
                  className="form-select"
                  value={checkoutData.code}
                  onChange={handleCheckoutData}
                  name="code"
                >
                  {codes.map((item) => (
                    <option value={item.dial_code} key={item.code}>
                      {item.name} ({item.dial_code})
                    </option>
                  ))}
                </select>
              </div>
              <div className="col">
                <input
                  type="tel"
                  class="form-control input-fld-sec"
                  id="exampleFormControlInput1"
                  name="phone"
                  value={checkoutData.phone}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }}
                  onChange={handleCheckoutData}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              Email <span>*</span>
            </label>
            <input
              type="email"
              class="form-control input-fld-sec"
              id="exampleFormControlInput1"
              name="email"
              value={checkoutData.email}
              onChange={handleCheckoutData}
            />
          </div>

          {/* <div
            className="col-lg-12 my-3"
            name="couple_status"
            // onChange={handleCheckoutData}
            // value={checkoutData.couple_status}
          >
            <div class="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="status"
                id="single"
                value="single"
                checked={checkoutData.couple_status === "single"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckData({
                      ...checkoutData,
                      couple_status: "single",
                    });
                  }
                }}
              />
              <label class="form-check-label" htmlFor="single">
                Single
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="partner"
                value="couple"
                checked={checkoutData.couple_status === "couple"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckData({
                      ...checkoutData,
                      couple_status: "couple",
                    });
                  }
                }}
              />
              <label class="form-check-label" htmlFor="partner">
                Couple
              </label>
            </div>
          </div> */}
          {/* 
          {checkoutData.couple_status === "couple" && (
            <>
              <div className="col-lg-12 my-3 chek-out-sec">
                <label for="exampleInputPassword1" class="form-label">
                  Partner's Name<span>*</span>
                </label>
                <input
                  type="text"
                  class="form-control input-fld-sec"
                  id="exampleFormControlInput1"
                  name="partner_name"
                  value={checkoutData.partner_name}
                  onChange={handleCheckoutData}
                />
              </div>

              <div className="col-lg-12 my-3 chek-out-sec ">
                <label for="exampleInputPassword1" class="form-label">
                  Partner's Passport Number <span>*</span>
                </label>
                <input
                  type="text"
                  // onKeyDown={(event) => {
                  //   if (!/[0-9]/.test(event.key)) event.preventDefault();
                  // }}
                  class="form-control input-fld-sec"
                  id="exampleFormControlInput1"
                  name="partner_passport_detail"
                  value={checkoutData.partner_passport_detail}
                  onChange={handleCheckoutData}
                />
              </div>
            </>
          )} */}

          <div className="col-lg-12 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              Association Name <span>*</span>
            </label>
            <input
              type="text"
              class="form-control input-fld-sec"
              id="exampleFormControlInput1"
              name="association_name"
              value={checkoutData.association_name}
              onChange={handleCheckoutData}
            />
          </div>

          <div className="col-lg-12 my-3 chek-out-sec">
            <label class="form-label">Salutation</label>
            <select
              className="form-select"
              name="salutation"
              value={checkoutData.salutation}
              onChange={handleCheckoutData}
            >
              <option value="delegate">Delegate</option>
              <option value="tabler">Tabler</option>
              <option value="circler">Circler</option>
              <option value="tangent">Tangent</option>
              <option value="agora">Agora</option>
              <option value="41ers">41ers</option>
              <option value="none">None</option>
            </select>
          </div>

          <div className="col-lg-12 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              Name of Club <span>*</span>
            </label>
            <input
              type="text"
              class="form-control input-fld-sec"
              id="exampleFormControlInput1"
              name="club_name"
              value={checkoutData.club_name}
              onChange={handleCheckoutData}
            />
          </div>

          <div className="col-lg-12 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              Nationality <span>*</span>
            </label>
            <input
              type="text"
              class="form-control input-fld-sec"
              id="exampleFormControlInput1"
              name="nationality"
              value={checkoutData.nationality}
              onChange={handleCheckoutData}
            />
          </div>
          <div className="col-lg-12 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              Which meeting will you attend? <span>*</span>
            </label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id={"councellor" + heading}
                value="Councillors Meeting"
                checked={checkoutData.meeting_type.includes(
                  "Councillors Meeting"
                )}
                onChange={(e) => {
                  handleMeeting(e, "Councillors Meeting");
                }}
              />
              <label class="form-check-label" for={"councellor" + heading}>
                Councillors Meeting
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id={"half" + heading}
                value="Half Yearly Meeting"
                onChange={(e) => {
                  handleMeeting(e, "Half Yearly Meeting");
                }}
                checked={checkoutData.meeting_type.includes(
                  "Half Yearly Meeting"
                )}
              />
              <label class="form-check-label" for={"half" + heading}>
                Half Yearly Meeting
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id={"regional" + heading}
                value="Regional Meeting"
                checked={checkoutData.meeting_type.includes("Regional Meeting")}
                onChange={(e) => {
                  handleMeeting(e, "Regional Meeting");
                }}
              />
              <label class="form-check-label" for={"regional" + heading}>
                Regional Meeting
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id={"none" + heading}
                value="None"
                checked={checkoutData.meeting_type.includes("none")}
                onChange={(e) => {
                  handleMeeting(e, "none");
                }}
              />
              <label class="form-check-label" htmlFor={"none" + heading}>
                None
              </label>
            </div>
          </div>
          <div className="col-lg-12 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              Dietary Requirements (Optional)
            </label>
            <input
              type="text"
              class="form-control input-fld-sec"
              id="exampleFormControlInput1"
              name="diatary_requirements"
              value={checkoutData.diatary_requirements}
              onChange={handleCheckoutData}
            />
          </div>

          <div className="col-lg-12 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              Allergies (if any)
            </label>
            <input
              type="text"
              class="form-control input-fld-sec"
              id="exampleFormControlInput1"
              name="allergies"
              value={checkoutData.allergies}
              onChange={handleCheckoutData}
            />
          </div>
          <div className="col-lg-12 my-3 chek-out-sec">
            <label for="disabledSelect" class="form-label">
              Visa letter required (optional)
            </label>
            <select
              id="disabledSelect"
              class="form-select"
              name="visa_letter"
              value={checkoutData.visa_letter}
              onChange={handleCheckoutData}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="col-lg-12 my-3 chek-out-sec">
            <label for="exampleInputPassword1" class="form-label">
              Passport Number
            </label>
            <input
              type="text"
              class="form-control input-fld-sec"
              id="exampleFormControlInput1"
              name="passport_number"
              value={checkoutData.passport_number}
              onChange={handleCheckoutData}
            />
          </div>
          {/* <div class="col-lg-12 my-3 chek-out-sec">
            <label for="formFile" class="form-label">
              Upload Passport (optional)
            </label>
            <input
              class="form-control"
              type="file"
              id="formFile exampleFormControlInput1"
              name="upload_passport"
              accept=".jpg,.png"
              // value={checkoutData.upload_passport}
              onChange={handleCheckoutData}
            />
          </div> */}
        </form>
      </div>
    </>
  );
}

export default CheckoutForm;
