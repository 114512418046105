import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AddToCart } from "../../helpers/CartHelper";

const Regcard = ({ data, imageURL }) => {
  return (
    <>
      <div className="">
        <NavLink to={`/registration/registrationdetail/${data?.id}`}>
          <div class="card tours-crd ">
            <div className="reg-page">
              <div className="reg-img">
                <img
                  src={imageURL + "/" + data.image}
                  class="card-img-top"
                  alt="card images"
                />
              </div>
              <div className="overlayss">
                <p>{data.name}</p>
              </div>
            </div>
            <div class="card-body text-center reg-crd-txt">
              <p class="card-text text-decoration-none">$ {data.price}</p>
            </div>
            <div className="card-btn text-center mb-3">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  AddToCart({
                    image: imageURL + "/" + data.image,
                    title: data.name,
                    price: data.price,
                    type: "package",
                    id: data.id,
                  });
                }}
                class="btn"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </NavLink>
      </div>
    </>
  );
};

export default Regcard;
