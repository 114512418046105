import React, { useEffect, useState } from "react";
import Cartcounter from "../Component/Cartcounter";
import { Link, useNavigate } from "react-router-dom";
import {
  GetCartItems,
  getSubTotal,
  getTotalCartAmount,
  removeCartItem,
} from "../helpers/CartHelper";

const Cartpage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setCartItems(GetCartItems());
  }, []);

  window.addEventListener("cart-change", () => {
    setCartItems(GetCartItems());
  });

  return (
    <>
      <div className="pagesection">
        <div className="container">
          {cartItems.length <= 0 ? (
            <h4>No Items in cart</h4>
          ) : (
            <table class="table">
              <thead className="product-tbl">
                <tr>
                  <th scope="col">Products</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Unit Price</th>

                  <th scope="col">Subtotal</th>
                  <th scope="col">Remove</th>
                </tr>
              </thead>
              <tbody className="product-data">
                {cartItems?.map((item, id) => (
                  <tr>
                    <td width="15%">
                      <div className="d-flex h-[170px]">
                        <div className="product-img">
                          <img src={item?.image} alt={item?.title}></img>
                        </div>
                        <p className="my-auto ms-3 ">{item?.title}</p>
                      </div>
                    </td>
                    <td width="15%">
                      <div className="prod-sd-sec">
                        <Cartcounter
                          index={id}
                          id={item?.id}
                          type={item?.type}
                          reloader={reload}
                          setReloader={setReload}
                        />
                      </div>
                    </td>
                    <td width="15%">
                      <div className="prod-sd-sec">$ {item.price}</div>
                    </td>

                    <td width="15%">
                      <div className="prod-sd-sec">
                        $ {item.price * item.quantity}
                      </div>
                    </td>
                    <td width="15%">
                      <div className="prod-sd-sec">
                        <i
                          class="fa-solid fa-trash mx-4"
                          onClick={() => removeCartItem(id)}
                        ></i>
                      </div>
                    </td>
                  </tr>
                ))}

                <tr>
                  <td colSpan="3">Total</td>
                  <td>$ {getTotalCartAmount()}</td>
                  <td className="card-btn">
                    <Link to="/cart/checkout" class="btn ms-5">
                      Checkout
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Cartpage;
