import React, { useEffect, useState } from "react";
import axios from "axios";
import { DetailLoader } from "../Loaders/DetailPageLoader";
import parse from "html-react-parser"

const Childrencornerpage = () => {
  const [childdata, setChilddata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [childimg,setChildImg]=useState(true)

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}childrentour/get`,
    })
      .then((res) => {
        let response = res?.data?.data?.childrentour;
        console.log(
          "this is childeren response data : ",
          res.data.data.childrentour
        );
        setChilddata(response);
        setLoading(false);
        console.log("this is children image url",res?.data?.data?.accommodation_images_base_url)
        setChildImg(res?.data?.data?.accommodation_images_base_url)

      })
      .catch((err) => {
        console.log("this is error", err);
        setLoading(false);
      });
  }, []);

  //   console.log("here we print", childdata);
    console.log("here we print", childdata?.children_tour_images?.image);

  if (loading) {
    return (
      <div className="container my-5">
        <DetailLoader />
      </div>
    );
  }

  return (
    <>
      <div className="pagesection">
        <div className="container">
          <>
            <div className="child-cnr-img">
              <img src={`${childimg}/${childdata?.children_tour_images?.[0]?.image}`} alt=""></img>
            </div>
            <div className="top-txt">
              <h5>{childdata.title}</h5>
              <p>{ parse( childdata.description || "")} </p>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Childrencornerpage;
