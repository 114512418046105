import React, { useState, useEffect } from "react";
import Pretourpagecrd from "../Component/Pretourpagecrd";
import axios from "axios";
import GridLoader from "../Loaders/GridLoader";

const Daytourpage = () => {
  const [dayTour, setDayTour] = useState([]);
  const [optionalTour, setOptionalTour] = useState([]);
  const [cardImage, setCardImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}daytour/get`,
    })
      .then((res) => {
        let response = res?.data?.data?.daytours?.data;

        let tempDayTour = response.filter((item) => item.optional == "0");

        let tempOptionalTourTour = response.filter(
          (item) => item.optional != "0"
        );

        setDayTour(tempDayTour);
        setOptionalTour(tempOptionalTourTour);
        // console.log("here we print reaponse", res?.data?.data?.daytours?.data);
        // console.log("imageurl path", res?.data?.data?.main_image_base_url);
        setCardImage(res?.data?.data?.main_image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("this is error", err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="pagesection">
        <div className="container">
          <div className="sub-section text-center">
            <h5 className="title m-0">Day Tours</h5>
          </div>
          {loading ? (
            <GridLoader item={2} row={3} />
          ) : (
            <div className="row">
              {dayTour.map((get, keys) => (
                <>
                  <div className="col-lg-6 col-md-6">
                    <Pretourpagecrd
                      data={get}
                      imageurl={cardImage}
                      type="day"
                    />
                  </div>
                </>
              ))}
            </div>
          )}

          {optionalTour?.length > 0 && (
            <>
              <div className="sub-section text-center">
                <h5 className="title m-0">Optional Day Tours</h5>
              </div>
              {loading ? (
                <GridLoader item={2} row={3} />
              ) : (
                <div className="row">
                  {optionalTour.map((get, keys) => (
                    <>
                      <div className="col-lg-6 col-md-6">
                        <Pretourpagecrd
                          data={get}
                          imageurl={cardImage}
                          type="day"
                        />
                      </div>
                    </>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Daytourpage;
