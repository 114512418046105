import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const DetailLoader = () => {
  return (
    <div>
      <Skeleton width={"100%"} height={"30rem"} />
      <div className="my-2">
        <Skeleton width={"30%"} />
      </div>

      <div className="row">
        <div className="col-12 gy-2">
          <Skeleton width={"100%"} />
          <Skeleton width={"100%"} />

          <Skeleton width={"100%"} />
          <Skeleton width={"100%"} />
          <Skeleton width={"100%"} />
          <Skeleton width={"100%"} />
          <Skeleton width={"100%"} />

          <Skeleton width={"100%"} />
          <Skeleton width={"100%"} />
          <Skeleton width={"100%"} />
        </div>
      </div>
    </div>
  );
};
