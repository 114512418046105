import React from "react";
import { useNavigate, Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="foot-top-sec">
          <div className="container">
            <div className="row p-4">
              <div className="col-lg-3 col-md-6">
                <div className="foot-logo">
                  <img src="/images/image 2.png" alt="footer logo"></img>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 foot-link mt-3">
                <ul>
                  <li>
                    <p>Follow as on Social Media</p>
                  </li>
                </ul>
                <div className="social-med-sec d-flex justify-content-start ps-4 pb-2">
                  <div className="instagram px-1">
                    <a
                      href="https://www.instagram.com/rtihym"
                      target={"_blank"}
                    >
                      {" "}
                      <img src="/images/instagram.png"></img>
                    </a>
                  </div>
                  <div className="facebook px-1">
                    <a href="https://www.facebook.com/rtihym" target={"_blank"}>
                      {" "}
                      <img src="/images/facebook.png"></img>
                    </a>
                  </div>
                  <div className="youtube px-1">
                    <a href="https://www.youtube.com/@rtihym" target={"_blank"}>
                      <img src="/images/youtube.png"></img>
                    </a>
                  </div>
                </div>
                {/* <ul>
                                    <li><Link to="/termsandcondations">Terms and conditions</Link></li>
                                </ul> */}
              </div>
              <div className="col-lg-3 col-md-6 foot-link mt-3">
                <ul>
                  <li>
                    <p>Visit our website</p>
                  </li>
                  <li>
                    <a href="https://rtn.org.np/" target={"_blank"}>
                      rtn.org.np
                    </a>
                  </li>

                  <li className="mt-3">
                    <Link to="/terms&condition">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="foot-btm-sec">
          <div className="container">
            <div className="row p-3">
              <div className="col-lg-6 col-md-6 col-sm-12 sub-foot">
                <a href="https://rtn.org.np" target="_blank">
                  {" "}
                  Copyright © {new Date().getFullYear()}, Round Table Nepal. All
                  rights reserved
                </a>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 sub-foot text-end">
                <a href="#"> Powered By: Dynavativ Pvt Ltd</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
