import React, { useEffect, useState } from "react";
import Regcard from "./Regcard";
import axios from "axios";
import GridLoader from "../../Loaders/GridLoader";

const Regexcludacc = () => {
  const [registration, setRegistration] = useState([]);
  const [imageURL, setImageURL] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}package/get`,
    })
      .then((res) => {
        let response = res?.data?.data?.regalias?.data;
        setRegistration(response);
        setImageURL(res.data.data?.image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("this is error", err);
        setLoading(false);
      });
  }, []);
  console.log(registration);

  return (
    <>
      <div className="container my-5">
        <div className="sub-section text-center">
          <h5 className="title m-0">
            Registration for lazy owl (15th December 2023 onwards )
          </h5>
        </div>
        <div className="row justify-content-center">
          {loading ? (
            <GridLoader />
          ) : (
            registration?.slice(5, 11)?.map((get, keys) => (
              <div className="col-lg-2 col-md-4">
                <Regcard data={get} imageURL={imageURL} />
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Regexcludacc;
