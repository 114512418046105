import React, { useState, useEffect } from "react";
import Tourcard from "./Tourcard";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import GridLoader from "../Loaders/GridLoader";

const Pretours = () => {
  const [pretour, setPretour] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}pretour/get`,
    })
      .then((res) => {
        let response = res?.data?.data?.pretours?.data;
        setPretour(response);

        setImagePath(res?.data?.data?.main_image_base_url);

        setLoading(false);
      })
      .catch((err) => {
        console.log("this is error", err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="section med-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="sub-section">
                <h5 className="title m-0">Pre-tours</h5>
              </div>
            </div>

            {loading ? (
              <GridLoader item={4} />
            ) : (
              <>
                <div className="col-lg-6 col-md-12 more-btn">
                  <Link to="/tour/pre-tour" class="text-decoration-none">
                    <button type="button" class="btn">
                      More <i class="fa-solid fa-arrow-right"></i>
                    </button>
                  </Link>
                </div>
                {pretour?.slice(0, 4)?.map((get, keys) => (
                  <div className="col-md-4">
                    <Tourcard type="pre" data={get} imageurl={imagePath} />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pretours;
