import React, { useEffect, useState } from "react";
import {
  Link,
  matchPath,
  matchRoutes,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { getCart } from "../helpers/CartHelper";

const Navbar = ({ navigateMe }) => {
  const routes = [{ path: "/tour/:id" }];
  const location = useLocation();
  let isMatch = matchRoutes(routes, location);

  const [total, setTotal] = useState(0);
  useEffect(() => {
    setTotal(getCart());
  }, []);

  window.addEventListener("cart-change", () => {
    setTotal(getCart());
  });

  const closeMenu = () => {
    let menu = document.getElementById("menu");
    console.log("menu is", menu);
    menu.classList.remove("show");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg rtn-fix-nav">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="mobile-head">
            <h5 className="mobile-txt">RTI Half-Yearly Meeting</h5>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <nav>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav-itm-lst">
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => navigateMe("schedule")}
                    className="nav-link"
                    to="/"
                  >
                    {" "}
                    Schedule
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className={
                      isMatch
                        ? "nav-link active dropdown-toggle"
                        : "nav-link dropdown-toggle"
                    }
                    role="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="inside"
                    aria-expanded="false"
                    id="mainMenu"
                  >
                    Tours
                  </a>
                  <ul className="dropdown-menu drop-dwn-ele" id="menu">
                    <li onClick={closeMenu}>
                      <NavLink className="dropdown-item " to="/tour/pre-tour">
                        Pre-Tour
                      </NavLink>
                    </li>
                    <li onClick={closeMenu}>
                      <NavLink className="dropdown-item" to="/tour/day-tour">
                        Day-Tour
                      </NavLink>
                    </li>
                    {/* <li onClick={closeMenu}><NavLink className="dropdown-item" to="/tour/childrencorner">Children corner</NavLink></li> */}
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    onClick={() => navigateMe("accomodation")}
                    className="nav-link"
                    to="/"
                  >
                    Accomodation
                  </Link>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/registration">
                    Registration
                  </NavLink>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => navigateMe("contactus")}
                    className="nav-link"
                    to="/"
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="lock-icn">
            <NavLink className="nav-link" to="/cart">
              <i class="fa-solid fa-cart-shopping"></i>
              <span class="badge">{total}</span>
            </NavLink>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
