// import React, { useEffect, useState } from "react";
// import axios from "axios";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { AddToCart } from "../helpers/CartHelper";

const Tourcard = ({ data, imageurl, type }) => {
  return (
    <>
      <Link
        to={
          type === "day"
            ? `/tour/day-tour/daytourdescription/${data?.id}`
            : `/tour/pre-tour/pretourdescription/${data?.id}`
        }
        class="text-decoration-none "
      >
        <div class="card tours-crd mb-4">
          <div className="crd-img">
            <img
              src={`${imageurl}/${data?.main_image}`}
              class="card-img-top"
              alt="card images"
            />
          </div>
          <div class="card-body text-center crd-txt">
            <h5 class="card-title">{data.title} </h5>
            <h5 class="card-title sub-title">{data.subtitle}</h5>
            <p class="card-text">$ {data.price}</p>
            <div className="card-btn text-center mb-3">
              <button
                class="btn"
                onClick={(e) => {
                  e.preventDefault();
                  AddToCart({
                    image: `${imageurl}/${data?.main_image}`,
                    title: data?.title,
                    quantity: 1,
                    price: data?.price,
                    type: "tour",
                    id: data?.id,
                  });
                }}
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Tourcard;
