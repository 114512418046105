import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Accomodationcrd = ({ data, imageURL }) => {
  return (
    <>
      <NavLink to={`/accomodation/accomodationdescription/${data?.id}`}>
        <div className="acc-mod-crd">
          <div className="acc-mod-img">
            <img
              src={imageURL + "/" + data?.main_image}
              alt="accomodation images"
            ></img>
          </div>
          <div className="overlay">
            <div className="content">
              <h5>{data?.title}</h5>
              <p>
                <i class="fa-sharp fa-solid fa-location-dot"></i>&nbsp;
                {data?.address}
              </p>
              <button type="button" class="btn">
                More info
              </button>
            </div>
          </div>
        </div>
      </NavLink>
    </>
  );
};

export default Accomodationcrd;
