import React, { useEffect, useState } from "react";
import Cartcounter from "../Cartcounter";
import axios from "axios";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { AddToCart } from "../../helpers/CartHelper";
import { DetailLoader } from "../../Loaders/DetailPageLoader";

const Regdetailcard = () => {
  const [registrationdtl, setRegistrationdtl] = useState([]);
  const [imageURLdtl, setImageURLdtl] = useState();
  const paramsid = useParams();
  const [loading, setLoading] = useState(false);

  console.log("params data are : ", paramsid?.id);

  useEffect(() => {
    setLoading(true);

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}package/get/${paramsid?.id}`,
    })
      .then((res) => {
        let response = res?.data?.data?.regalia;
        console.log("here print reg data reg", res?.data?.data?.regalia);

        setRegistrationdtl(response);
        setImageURLdtl(res.data.data?.image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("this is error", err);
        setLoading(false);
      });
  }, [paramsid?.id]);
  // console.log(registrationdtl);

  if (loading) {
    return (
      <div className="container my-5">
        {" "}
        <DetailLoader />
      </div>
    );
  }

  return (
    <>
      <div className="pagesection">
        <div className="container">
          <div className="row d-flex">
            <div className="col-lg-5 col-md-12 reg-imgg">
              <img src={imageURLdtl + "/" + registrationdtl.image} alt=""></img>
            </div>
            <div className="col-lg-7 col-md-12 p-4">
              <div className="text-reg">
                <h5 className="title m-0">{registrationdtl.name}</h5>
                <h6>$ {registrationdtl.price}</h6>
                <p>{parse(registrationdtl.description || "")}</p>
              </div>
              <div className="d-flex card-btn">
                {/* <Cartcounter /> */}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    AddToCart({
                      image: imageURLdtl + "/" + registrationdtl.image,
                      title: registrationdtl.name,
                      price: registrationdtl.price,
                      type: "package",
                      id: registrationdtl.id,
                    });
                  }}
                  class="btn "
                >
                  Add to Cart
                </button>
              </div>
              {/* <hr />
              <div className="bttm-txt">
                <p>
                  <strong>Categories:</strong> All products, All registration,
                  Registration(With accommodation)
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Regdetailcard;
