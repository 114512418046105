import React, { useState } from "react";
import {
  decreaseItem,
  getItemCount,
  increaseItem,
} from "../helpers/CartHelper";

const Cartcounter = ({ index, id, type, reloader, setReloader }) => {
  const [count, setCount] = useState(getItemCount(index) || 1);

  function increment() {
    if (count >= 4) {
      return;
    }
    setCount(count + 1);
    increaseItem(id, type, reloader, setReloader);
  }

  function decrement() {
    if (count > 1) {
      setCount(count - 1);
      decreaseItem(id, type, reloader, setReloader);
    }
  }

  return (
    <div className="crtcounter-btn">
      <button onClick={decrement} style={{ borderRight: "1px solid black" }}>
        -
      </button>
      <button>{count}</button>
      <button onClick={increment} style={{ borderLeft: "1px solid black" }}>
        +
      </button>
    </div>
  );
};

export default Cartcounter;
