import React, { useEffect, useState } from "react";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment/moment";
import GridLoader from "../Loaders/GridLoader";

const Schedule = () => {
  const [scheduledata, setSeduledata] = useState([]);
  const [loading, setLoading] = useState(true);
  // const apiDate = "2022-02-24T15:30:00.000Z";
  // const dateObj = new Date(apiDate);
  // const formattedDate = dateObj.datetoLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric' });

  // console.log(formattedDate);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}schedule/get`,
    })
      .then((res) => {
        let response = res?.data?.data?.schedules?.data;
        setSeduledata(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log("this is error", err);
        setLoading(false);
      });
  }, []);

  // console.log(scheduledata)

  return (
    <>
      <div className="section med-sec schedule">
        <div className="container" id="schedule">
          <div className="sub-section med-sub-sec text-center">
            <h5 className="title m-0">Schedule</h5>
            <p className="sub-title">Daily schedule is as follows.</p>
          </div>

          {loading ? (
            <GridLoader />
          ) : (
            <div className="row justify-content-center">
              {scheduledata?.map((schedule, keys) => (
                <>
                  <div
                    className="col-lg-2 col-md-3 scdl-sec text-center me-3"
                    data-bs-toggle="modal"
                    data-bs-target={`#exampleModal${keys}`}
                  >
                    <i class="fa-solid fa-calendar-days"></i>
                    <h5>
                      DAY-<span>{schedule.priority}</span>
                    </h5>
                    <p>{moment(schedule?.date).format("MMMM DD, YYYY")}</p>
                  </div>
                  <div
                    class="modal fade"
                    id={`exampleModal${keys}`}
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">
                            {moment(schedule?.date).format("MMMM DD, YYYY")}
                          </h1>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          {parse(schedule.content || "")}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Schedule;
